#imageContainer {
    border-radius: 3px;
    position: relative;
}

@media only screen and (min-width: 600px) {
    #imageContainer:hover {
        box-shadow: 0px 4px 24px 8px rgba(0, 0, 0, .75);
        z-index: 15;
    }
    .image {
        vertical-align: top;
    }
    .image-title {
        position: absolute;
        bottom: 0;
        padding-left: 16px;
        z-index: 20;
    }
    .image-title:hover {
        color: black;
    }
    .large {
        grid-column-end: span 2;
        grid-row-end: span 2;
    }
    .tall {
        grid-column-end: span 1;
        grid-row-end: span 3;
    }

    .gallery {
        width: 100%;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: auto auto auto auto;
        grid-gap: 0px;
        align-content: center;
        padding-bottom: 0px;
        padding-top: 0px;
    }

    .about {
        grid-column-end: span 3;
        display: flex;
        z-index: 1;
        margin-left: auto;
        margin-right: auto;
        padding-top: 32px;
        padding-bottom: 32px;
        justify-content: center;
        align-items: center;
        flex-flow: row nowrap;
    }
    .about-text {
        color: white;
        width: 80%;
        margin: auto;
        padding-right: 32px;
        padding-left: 32px;
        max-width: 700px;
    }
    .about-image {
        width:192px;
        border-radius: 50%;
        padding-right: 32px;
        padding-left: 32px;
        margin: auto;
    }
}

@media only screen and (max-width: 600px) {
    .gallery {
        margin-top: 0px;
        display: flex;
        flex-flow: column nowrap;
    }
    .image {
        vertical-align: top;
    }
    .about {
        z-index: 1;
        padding: 64px;
        padding-top: 24px;
        padding-bottom: 24px;
        text-align: center;
    }
    .about-text {
        color: white;
    }
    .about-image {
        width:192px;
        padding: 16px;
        border-radius: 50%;
    }
}
