.quote {
    grid-column-end: span 3;
    grid-row-end: span 2;
    display: flex;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
    padding-top: 96px;
    padding-bottom: 96px;
    justify-content: center;
    flex-flow: column nowrap;
    width: 60%;
    max-width: 600px;
    position: relative;
}
.quote-text:before {
    display: block;
    position: absolute;
    content: "\201C";
    font-size: 80px;
    left: -64px;
    top: 86px;
    color: darkslategrey;
}
.quote-text:after {
    display: block;
    position: absolute;
    content: "\201D";
    font-size: 80px;
    text-align: right;
    right: -64px;
    bottom: 60px;
    color: darkslategrey;
}
.quote-text {
    margin: auto;
    line-height: 175%;
}
.source {
    text-align: right;
    padding-top: 16px;
}

@media only screen and (min-width: 600px) {
    .about {
        grid-column-end: span 3;
        display: flex;
        z-index: 1;
        margin-left: auto;
        margin-right: auto;
        padding-top: 96px;
        padding-bottom: 96px;
        justify-content: center;
        align-items: center;
        flex-flow: row nowrap;

    }
    .about-text {
        color: white;
        width: 80%;
        margin: auto;
        padding-right: 32px;
        padding-left: 32px;
        max-width: 700px;
        line-height: 175%;
    }
    .about-image {
        width:192px;
        border-radius: 50%;
        padding-right: 32px;
        padding-left: 32px;
        margin: auto;
    }
    p {
        margin-top: 0px;
    }
}

@media only screen and (max-width: 600px) {
    .gallery {
        margin-top: 0px;
    }
    .about {
        z-index: 1;
        padding: 64px;
        padding-top: 24px;
        padding-bottom: 24px;
        text-align: center;
    }
    .about-text {
        color: white;
    }
    .about-image {
        width:192px;
        padding: 16px;
        border-radius: 50%;
    }
}
