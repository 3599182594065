@import url("https://fonts.googleapis.com/css?family=Caveat:700|Quicksand");

body {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: rgb(35,38,43);
  width:100vw;
  overflow-x:hidden;
}

h1 {
  font-family: 'Caveat', cursive;
}
h1 a, h1 a:hover {
    color: white;
    text-decoration: none;
}
h1, .title {
    color: white;
}

h3, h2 {
    font-family: 'Quicksand', sans-serif;
}

p {
  font-family: 'Quicksand', sans-serif;
  color: lightgrey;
}

img {
  width: 100%
}

a {
  color: lightgrey;
}
a:hover {
  color: white;
}
::-webkit-scrollbar { 
  display: none; 
}
