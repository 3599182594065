.modal {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0;
    overflow:auto;
    overflow-x: hidden;
    position: fixed;
    z-index: 50;
    background-color: rgba(35,38,43,.95);
    -webkit-backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    
}
.modal-body {
    padding-bottom: 96px;
    max-width: 1080px;
}
.modal-header {
    display: grid;
    grid-template-columns: auto 24px;
    padding-top: 16px;
}
.modal-title {
    color: white;
    margin-top: 0px;
}
.description {
    max-width: 75%;
}
.modal-content {
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, .75);
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 96px;
}
button {
    text-align: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-top: 0px;
    color: rgba(255, 255, 255, .5);
    background-color: transparent;
    border: none;
    font-size: 24px;
    font-weight: bold;
}
button:hover {
    color:white;
}
p {
    margin-top: 0px;
}

@media only screen and (min-width: 600px) {
    .modal {
        margin-top: 64px;
    }
    .modal-body {
        margin-left: 15%;
        margin-right: 15%;
    }
}
@media only screen and (max-width: 600px) {
    .modal {
        margin-top: 96px;
    }
    .modal-body {
        margin-left: 5%;
        margin-right: 5%;
    }
}