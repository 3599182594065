@import url(https://fonts.googleapis.com/css?family=Caveat:700|Quicksand);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: rgb(35,38,43);
  width:100vw;
  overflow-x:hidden;
}

h1 {
  font-family: 'Caveat', cursive;
}
h1 a, h1 a:hover {
    color: white;
    text-decoration: none;
}
h1, .title {
    color: white;
}

h3, h2 {
    font-family: 'Quicksand', sans-serif;
}

p {
  font-family: 'Quicksand', sans-serif;
  color: lightgrey;
}

img {
  width: 100%
}

a {
  color: lightgrey;
}
a:hover {
  color: white;
}
::-webkit-scrollbar { 
  display: none; 
}

.header {
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    display: grid;
    z-index: 75;
    background-color: rgba(0,0,0,0.875);
    -webkit-backdrop-filter: blur(5px);
    width:100vw;
    overflow: auto;
}

.social a, .social a:visited {
    color: rgba(255, 255, 255, .5);
}
.social a:hover {
    color: white;
}

@media only screen and (min-width: 600px) {
    .header {
        height: 64px;
        grid-template-columns: auto auto;
        grid-template-rows: 100%;
    }

    .headerBackground {
        height: 64px;
        width: 100%;
        background-image: url(/static/media/gradient.cbdd54a2.jpg);
        background-repeat: no-repeat;
        background-position: center top; 
        background-size: 100%;
        position: relative;
    }

    .title {
        padding-left: 32px;
        margin-top: 0px;
        padding-top: 8px;
    }

    .social {
        font-size: 18px;
        text-align: right;
        padding-right: 32px;
        padding-top: 20px;
    }
}

@media only screen and (max-width: 600px) {
    h3 {
        font-size: 16px;
    }

    .header {
        text-align: center;
        height: 96px;
    }

    .headerBackground {
        height: 96px;
        width: 100%;
        background-image: url(/static/media/gradient.cbdd54a2.jpg);
        background-repeat: no-repeat;
        background-position: center top; 
        background-size: 100%;
    }

    .title {
        margin-top: 8px;
        margin-bottom: 0px;
    }
    
    .social {
        font-size: 18px;
        margin-top: -4px;
    }

}

.footer {
    text-align: center;
    padding: 16px 32px 16px 32px;
    background-color: rgba(0, 0, 0, 0);
    -webkit-backdrop-filter: blur(5px);
}
.footer-background {
    background-image: url(/static/media/gradient.cbdd54a2.jpg);
    background-repeat: no-repeat;
    background-position: center top; 
    background-size: 100%;
}

.link, .date {
    color: rgba(255, 255, 255, 0.5);
    padding: 16px 32px 16px 32px;
}

@media only screen and (min-width: 600px) {
    .links {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
    }
}

@media only screen and (max-width: 600px) {
    .links {
        display: flex;
        justify-content: center;
        flex-flow: column nowrap;
    }
}
#imageContainer {
    border-radius: 3px;
    position: relative;
}

@media only screen and (min-width: 600px) {
    #imageContainer:hover {
        box-shadow: 0px 4px 24px 8px rgba(0, 0, 0, .75);
        z-index: 15;
    }
    .image {
        vertical-align: top;
    }
    .image-title {
        position: absolute;
        bottom: 0;
        padding-left: 16px;
        z-index: 20;
    }
    .image-title:hover {
        color: black;
    }
    .large {
        grid-column-end: span 2;
        grid-row-end: span 2;
    }
    .tall {
        grid-column-end: span 1;
        grid-row-end: span 3;
    }

    .gallery {
        width: 100%;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: auto auto auto auto;
        grid-gap: 0px;
        align-content: center;
        padding-bottom: 0px;
        padding-top: 0px;
    }

    .about {
        grid-column-end: span 3;
        display: flex;
        z-index: 1;
        margin-left: auto;
        margin-right: auto;
        padding-top: 32px;
        padding-bottom: 32px;
        justify-content: center;
        align-items: center;
        flex-flow: row nowrap;
    }
    .about-text {
        color: white;
        width: 80%;
        margin: auto;
        padding-right: 32px;
        padding-left: 32px;
        max-width: 700px;
    }
    .about-image {
        width:192px;
        border-radius: 50%;
        padding-right: 32px;
        padding-left: 32px;
        margin: auto;
    }
}

@media only screen and (max-width: 600px) {
    .gallery {
        margin-top: 0px;
        display: flex;
        flex-flow: column nowrap;
    }
    .image {
        vertical-align: top;
    }
    .about {
        z-index: 1;
        padding: 64px;
        padding-top: 24px;
        padding-bottom: 24px;
        text-align: center;
    }
    .about-text {
        color: white;
    }
    .about-image {
        width:192px;
        padding: 16px;
        border-radius: 50%;
    }
}

.quote {
    grid-column-end: span 3;
    grid-row-end: span 2;
    display: flex;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
    padding-top: 96px;
    padding-bottom: 96px;
    justify-content: center;
    flex-flow: column nowrap;
    width: 60%;
    max-width: 600px;
    position: relative;
}
.quote-text:before {
    display: block;
    position: absolute;
    content: "\201C";
    font-size: 80px;
    left: -64px;
    top: 86px;
    color: darkslategrey;
}
.quote-text:after {
    display: block;
    position: absolute;
    content: "\201D";
    font-size: 80px;
    text-align: right;
    right: -64px;
    bottom: 60px;
    color: darkslategrey;
}
.quote-text {
    margin: auto;
    line-height: 175%;
}
.source {
    text-align: right;
    padding-top: 16px;
}

@media only screen and (min-width: 600px) {
    .about {
        grid-column-end: span 3;
        display: flex;
        z-index: 1;
        margin-left: auto;
        margin-right: auto;
        padding-top: 96px;
        padding-bottom: 96px;
        justify-content: center;
        align-items: center;
        flex-flow: row nowrap;

    }
    .about-text {
        color: white;
        width: 80%;
        margin: auto;
        padding-right: 32px;
        padding-left: 32px;
        max-width: 700px;
        line-height: 175%;
    }
    .about-image {
        width:192px;
        border-radius: 50%;
        padding-right: 32px;
        padding-left: 32px;
        margin: auto;
    }
    p {
        margin-top: 0px;
    }
}

@media only screen and (max-width: 600px) {
    .gallery {
        margin-top: 0px;
    }
    .about {
        z-index: 1;
        padding: 64px;
        padding-top: 24px;
        padding-bottom: 24px;
        text-align: center;
    }
    .about-text {
        color: white;
    }
    .about-image {
        width:192px;
        padding: 16px;
        border-radius: 50%;
    }
}

.modal {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0;
    overflow:auto;
    overflow-x: hidden;
    position: fixed;
    z-index: 50;
    background-color: rgba(35,38,43,.95);
    -webkit-backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    
}
.modal-body {
    padding-bottom: 96px;
    max-width: 1080px;
}
.modal-header {
    display: grid;
    grid-template-columns: auto 24px;
    padding-top: 16px;
}
.modal-title {
    color: white;
    margin-top: 0px;
}
.description {
    max-width: 75%;
}
.modal-content {
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, .75);
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 96px;
}
button {
    text-align: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-top: 0px;
    color: rgba(255, 255, 255, .5);
    background-color: transparent;
    border: none;
    font-size: 24px;
    font-weight: bold;
}
button:hover {
    color:white;
}
p {
    margin-top: 0px;
}

@media only screen and (min-width: 600px) {
    .modal {
        margin-top: 64px;
    }
    .modal-body {
        margin-left: 15%;
        margin-right: 15%;
    }
}
@media only screen and (max-width: 600px) {
    .modal {
        margin-top: 96px;
    }
    .modal-body {
        margin-left: 5%;
        margin-right: 5%;
    }
}
