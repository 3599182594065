.footer {
    text-align: center;
    padding: 16px 32px 16px 32px;
    background-color: rgba(0, 0, 0, 0);
    -webkit-backdrop-filter: blur(5px);
}
.footer-background {
    background-image: url("media/gradient.jpg");
    background-repeat: no-repeat;
    background-position: center top; 
    background-size: 100%;
}

.link, .date {
    color: rgba(255, 255, 255, 0.5);
    padding: 16px 32px 16px 32px;
}

@media only screen and (min-width: 600px) {
    .links {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
    }
}

@media only screen and (max-width: 600px) {
    .links {
        display: flex;
        justify-content: center;
        flex-flow: column nowrap;
    }
}